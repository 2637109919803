const ServiceData = [
        {
          id:"is1",
          icon:"fas fa-laptop-code",
          title: "Desarrollo Web",
          text: "Todo un equipo de expertos durante el desarrollo de tu proyecto para ayudarte a definir las mejores prácticas y procesos.",
          iconBackground:"#000"
        },
        {
          id:"is2",
          icon:"fas fa-mobile-alt",
          title: "Desarrollo móvil",
          text: "Creamos aplicaciones móviles nativas y Multiplataforma para iOS y Android.",
          iconBackground:"#000"
        },
        {
          id:"is3",
          icon:"fas fa-user-check",
          title: "Experiencia de usuario",
          text: "Adoptar una estrategia centrada en el cliente/usuario ayuda a destacar entre la competencia.",
          iconBackground:"#000"
        },
        {
          id:"is4",
          icon:"fas fa-cloud-upload-alt",
          title: "Servicios en la nube",
          text: "Conjunto de soluciones en la nube para manejar grandes volúmenes de datos y aplicativos. ",
          iconBackground:"#000"
        },
        {
          id:"is5",
          icon:"fas fa-chart-line",
          title: "Analítica",
          text: "Gobierna y analiza tus datos aprovechando los beneficios de las tecnologías BigData.",
          iconBackground:"#000"
        },
        {
          id:"is6",
          icon:"fas fa-shield-alt",
          title: "Consultoria de seguridad",
          text: "Implican actividades de identificación, análisis, evaluación y tratamiento de riesgos y monitoreo.",
          iconBackground:"#000"
        }
    
    ]
  export default ServiceData;